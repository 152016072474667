import React from 'react';
import logo from './assets/logo.coderBR.com.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
       
        <h2>
          Se desejar saber mais sobre desenvolvimento de software, use o formulário de contato.</h2>
      </header>
      <main>
        
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeoxZsro6B2TgEQfV_J_1yPTA16V1iRbf7rHd0_35IYaXinog/viewform?embedded=true" width="640" height="864" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
      </main>
      <hr />
      <section>
        <h2>Artigos porto.academy (collab temporário)</h2>
        <p>
        <a href="https://portoacademy.wordpress.com/2019/02/28/o-que-e-flutter/">Flutter - Introdução 1/3</a>
        </p>
        <p>
        <a href="https://portoacademy.wordpress.com/2019/03/01/flutter-2-ambiente-de-desenvolvimento-flutter/">Flutter - Introdução 2/3</a>
        </p>
        <p>
        <a href="https://portoacademy.wordpress.com/2019/03/02/flutter-3-meu-primeiro-projeto-em-flutter/">Flutter - Introdução 3/3</a>
        </p>
      </section>
    </div>
  );
}

export default App;
